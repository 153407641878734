import React from 'react';
import MapView from './components/MapView';
import './App.css';
import ReactGA from "react-ga4";

// const TRACKING_ID = "UA-143785214-1"; // YOUR_OWN_TRACKING_ID
const TRACKING_ID = "G-CJN50V7Y8E"; // YOUR_OWN_TRACKING_ID

ReactGA.initialize(TRACKING_ID);
ReactGA.send("pageview");

function App() {
  return (
    <div className="App">
      <MapView/>
    </div>
  );
}

export default App;
