import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="Footer">
            <p>
                <a href="mailto:albordignon@gmail.com"
                >Desenvolvido por André Luís Bordignon</a
                >
            </p>
            <a rel="license" href="http://creativecommons.org/licenses/by-nc/4.0/">
                <img
                    alt="Licença Creative Commons"
                    // style="border-width: 0;"
                    src="https://i.creativecommons.org/l/by-nc/4.0/88x31.png"
                />
            </a>
            <p>Este trabalho está licenciado com uma Licença</p>
            <a rel="license" href="http://creativecommons.org/licenses/by-nc/4.0/"
            >Creative Commons - Atribuição-NãoComercial 4.0 Internacional </a
            >
        </footer>
    )

}

export default Footer;
