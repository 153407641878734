import L from 'leaflet';

export const SchoolLocationIcon = L.icon({
  iconUrl: require('../assets/schoolicon3.png'),
  iconRetinaUrl: require('../assets/schoolicon3.png'),
  iconAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [30, 30],
  className: 'leaflet-venue-icon'
});

export const ResearchCenterIcon = L.icon({
  iconUrl: require('../assets/researchcentericon.png'),
  iconRetinaUrl: require('../assets/researchcentericon.png'),
  iconAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [30, 30],
  className: 'leaflet-venue-icon'
});

export const SchoolCircleIcon = L.circle({
  color: 'red',
  fillColor: '#f03',
  weight: 1,
  fillOpacity: 0.2
})