import React from 'react';
import { Circle } from 'react-leaflet';


const PlotStudentsLocation = ({ students, color, course }) => {

  return (
    <div className="PlotStudentsLocation">
      {students.map((student, index) => {
        if(student.curso !== course && course !== 'all'){
          return null;
        }
        const factorLongitude = Math.random()*0.01%0.01;
        const factorLatitude = Math.random()*0.01%0.01;

        const longitude = student.lng + factorLongitude;
        const latitude = student.lat + factorLatitude;
        return (
          <Circle
            key={index}
            center={{ lat: latitude, lng: longitude }}
            fillColor={color}
            color={color}
            fillOpacity="0.2"
            // radius={escola.quantidadeVotos * radiusFactor}
            radius={3}

          >
          </Circle>
        );
      })
      }
    </div >
  )

}

export default PlotStudentsLocation;
