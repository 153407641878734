import React, { useState, useEffect, useCallback } from 'react';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import students2019 from '../assets/estudantes2019.json';
import students2020 from '../assets/estudantes2020.json';
import students2021 from '../assets/estudantes2021.json';
import students2022 from '../assets/estudantes2022.json';
import students2023 from '../assets/estudantes2023.json';
import students2024 from '../assets/estudantes2024.json';
import Footer from '../components/Footer/Footer';
import './MapView.css';
import DrawCampinas from './DrawCampinas/DrawCampinas';
import PlotStudentsLocation from '../components/PlotStudentsLocation/PlotStudentsLocation';
import { SchoolLocationIcon } from './SchoolLocationIcon';

const MapView = () => {
  const [currentLocation] = useState({ lat: -22.930556, lng: -47.058833 });
  const [zoom] = useState(11.5);
  const [countStudents, setCountStudents] = useState(0);
  const [year, setYear] = useState();
  const [students, setStudents] = useState([]);
  const [circleColor, setCircleColor] = useState('blue');
  const [courses, setCourses] = useState([]);
  const [courseSelected, setCourseSelected] = useState('all');
  const [studentCountByCourse, setStudentCountByCourse] = useState([]);

  console.log(students2022.estudantes.length);
  console.log(students2023.estudantes.length);
  console.log(students2024.estudantes.length);

  useEffect(() => {
    setYear(2024);
    setStudents(students2024.estudantes);
    setCountStudents(students2024.estudantes.length);
    setCircleColor('black');
  }, []);

  const handleCourses = useCallback(() => {
    const newCourses = [];
    const newStudentCountByCourse = [];
    students.map((student) => {
      if (newCourses.indexOf(student.curso) === -1) {
        newCourses.push(student.curso);
      }
      newStudentCountByCourse[student.curso] =
        (newStudentCountByCourse[student.curso] || 0) + 1;
      return null;
    });
    setCourses(newCourses);
    setStudentCountByCourse(newStudentCountByCourse);
  }, [students]);

  useEffect(() => {
    handleCourses();
  }, [students, handleCourses]);

  const handleChangeYear = (event) => {
    if (event.target.value === '2024') {
      console.log('Estudantes 2024');
      setStudents(students2024.estudantes);
      setCountStudents(students2024.estudantes.length);
      setCircleColor('black');
    }
    if (event.target.value === '2023') {
      console.log('Estudantes 2023');
      setStudents(students2023.estudantes);
      setCountStudents(students2023.estudantes.length);
      setCircleColor('red');
    }
    if (event.target.value === '2022') {
      console.log('Estudantes 2022');
      setStudents(students2022.estudantes);
      setCountStudents(students2022.estudantes.length);
      setCircleColor('purple');
    }
    if (event.target.value === '2021') {
      setStudents(students2021.estudantes);
      setCountStudents(students2021.estudantes.length);
      setCircleColor('blue');
    }
    if (event.target.value === '2020') {
      setStudents(students2020.estudantes);
      setCountStudents(students2020.estudantes.length);
      setCircleColor('red');
    }
    if (event.target.value === '2019') {
      setStudents(students2019.estudantes);
      setCountStudents(students2019.estudantes.length);
      setCircleColor('green');
    }
    if (event.target.value === '2018') {
      setStudents(students2020.estudantes);
      setCountStudents(students2020.estudantes.length);
      setCircleColor('#663300');
    }
    setYear(event.target.value);
  };

  const handleChangeCourse = (event) => {
    setCourseSelected(event.target.value);
  };

  return (
    <Map center={currentLocation} zoom={zoom}>
      <div className='titulo'>
        <p>
          Distribuição Geográfica da Moradia dos Estudantes do IFSP Campinas -{' '}
          {year}
        </p>
      </div>
      <TileLayer
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      <DrawCampinas />
      <PlotStudentsLocation
        students={students}
        color={circleColor}
        course={courseSelected}
      />
      {/* <Marker position={[-22.8514551, -47.1272708]} icon={ResearchCenterIcon} >
        <Popup>
          <div className='poup-text'>CTI</div>
        </Popup>)
      </Marker> */}
      <Marker position={[-22.947526, -47.14915752]} icon={SchoolLocationIcon}>
        <Popup>
          <div className='poup-text'>IFSP Campinas</div>
        </Popup>
        )
      </Marker>
      <Marker position={[-22.84909, -47.23134]} icon={SchoolLocationIcon}>
        <Popup>
          <div className='poup-text'>IFSP Hortolândia</div>
        </Popup>
        )
      </Marker>
      <div className='controles'>
        <label>Selecione o ano</label>
        <select onChange={handleChangeYear}>
          <option value='2024'>2024</option>
          <option value='2023'>2023</option>
          <option value='2022'>2022</option>
          <option value='2021'>2021</option>
          <option value='2020'>2020</option>
          <option value='2019'>2019</option>
          <option value='2018'>2018</option>
        </select>
        <br />
        <label>Selecione o curso</label>
        <select onChange={handleChangeCourse}>
          <option value='all'>Todos</option>
          {courses.map((course) => {
            return (
              <option key={course} value={course}>
                {course}
              </option>
            );
          })}
        </select>
        <div id='boxTotalEstudantes'>
          {courseSelected === 'all' ? (
            <p>Total de estudantes: {countStudents}</p>
          ) : (
            <p>Total de estudantes: {studentCountByCourse[courseSelected]}</p>
          )}
        </div>
      </div>
      <Footer />
    </Map>
  );
};

export default MapView;
